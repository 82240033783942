export const IS_PROD = process.env.NODE_ENV === 'production' || window.location.href.startsWith('https://home.rathnas.us/')
export const API_BASE = window.location.href.startsWith('http://localhost:3000') ? 'http://localhost:8080/'
  : window.location.href.startsWith('https://testhome.rathnas.com/') ? 'https://testentwineapi.rathnas.com/'
    : IS_PROD ? 'https://entwineapi.rathnas.com/'
      : 'https://entwineapi.rathnas.com/'

// https://firebase.google.com/docs/reference/js/auth#autherrorcodes
const authFultCodeToMsg = new Map<string, string>()
authFultCodeToMsg.set('auth/email-already-in-use', 'Email already in use')
authFultCodeToMsg.set('auth/email-change-needs-verification', 'Email needs verification')
authFultCodeToMsg.set('auth/requires-recent-login', 'Please relogin, crednetial expired')
authFultCodeToMsg.set('auth/invalid-email', 'Invalid email')
authFultCodeToMsg.set('auth/user-not-found', 'Email not found, you need to register this email first')
export const authFultMsgById = (id: string): string => {
  const rsp = authFultCodeToMsg.get(id)
  return rsp ? rsp : 'Unknown error'
}

export const dateFormat = 'YYYY-MM-DD'
export const enum FormOperationType {
  CREATE = 'create',
  UPDATE = 'update',
  READ = 'read',
  DELETE = 'delete',
  NESTED = 'nested'
}
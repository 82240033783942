import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'
import { RootState } from '../../app/store'
import axios from 'axios'
import { API_BASE } from '../../app/const'
import { getValue, fetchAndActivate } from 'firebase/remote-config'
import { AppError } from '../../app/types'
import { fireauth, remoteConfig } from '../../app/fire'

const providerGoogle = new GoogleAuthProvider()
providerGoogle.addScope('openid, https://www.googleapis.com/auth/userinfo.email, https://www.googleapis.com/auth/userinfo.profile')

export interface AppUser {
  uid?: string
  photoURL?: string
  email?: string
  userId: string
  displayName: string | null
  userEmail: string | null
  picture: string | null
  providerId: string
  emailVerified: boolean
  roles: string[]
  publicVisible?: boolean
  slug?: string
  referralUserId?: string
  phoneNumber?: string
  phoneCountryCode?: string
}
export interface AuthState {
  user: AppUser
  isInitializing: boolean
  isLoading: boolean
  isAuthenticated: boolean
  isError: boolean
  error?: AppError
  redirectAfterSignIn: string
}
const initialState: AuthState = {
  redirectAfterSignIn: '/',
  isInitializing: true,
  isLoading: true,
  isError: false,
  isAuthenticated: false,
} as AuthState

const fetchAppUser = async () => {
  try {
    const userProfile = await axios.get(`${API_BASE}apps/user`, {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${await fireauth.currentUser?.getIdToken()}`
      }
    })
    return {
      user: {
        ...userProfile?.data
      }
    }
  } catch (err) {
    return {
      user: fireauth.currentUser
    }
  }
}
export const authInitAsync = createAsyncThunk<number, void>('auth/authInitAsync', async () => new Promise((resolve) => setTimeout(() => { resolve(0) }, 1000)))
export const signOutAsync = createAsyncThunk('auth/signOutAsync', async () => signOut(fireauth))
export const signInAsync = createAsyncThunk(
  'auth/signInAsync', async ({ redirectTo }: { redirectTo: string }, { getState }) => {
    const state = getState() as RootState
    if (!state.auth.isAuthenticated) {
      providerGoogle.setCustomParameters({ redirectTo })
      await signInWithPopup(fireauth, providerGoogle)
      return await fetchAppUser()
    }
  }
)
export const authStateChangeAsync = createAsyncThunk('auth/authStateChangeAsync', async () => { return await fetchAppUser() })

export const remoteConfigAsync = createAsyncThunk('auth/remoteConfigAsync', async (_, { getState }) => {
  const state = getState() as RootState
  if (!state.auth.isAuthenticated) {
    return fetchAndActivate(remoteConfig).then(() => {
      return {
        isMaintenance: getValue(remoteConfig, 'isMaintenance').asBoolean(),
        maintenanceMessage: getValue(remoteConfig, 'maintenanceMsg').asString(),
        futureMaintenanceMessage: getValue(remoteConfig, 'futureMaintenanceMsg').asString(),
      }
    }).catch((err) => {
      console.log('remoteConfig fetch failed', err)
      return { isMaintenance: false, maintenanceMessage: '', futureMaintenanceMessage: '' }
    })
  }
})
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authStateChange: (state, action: PayloadAction<{ user: AppUser }>) => {
      state.isAuthenticated = !!action.payload.user
      state.user = action.payload.user
    },
  }, extraReducers: (builder) => {
    builder.addCase(authInitAsync.pending, (state) => {
      state.isLoading = true
      state.isInitializing = true
      state.isError = false
      state.error = undefined
    }).addCase(authInitAsync.fulfilled, (state) => {
      state.isLoading = false
      state.isInitializing = false
      state.isError = false
      state.error = undefined
    }).addCase(authInitAsync.rejected, (state, action) => {
      state.isLoading = false
      state.isInitializing = false
      state.isError = true
      state.error = { code: action.error.code, message: action.error.message }
    }).addCase(signInAsync.pending, (state) => {
      state.isLoading = true
    }).addCase(signInAsync.fulfilled, (state, action) => {
      state.isLoading = false
      state.isAuthenticated = true
      state.user = action.payload?.user
    }).addCase(signInAsync.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.error = { code: action.error.code, message: action.error.message }
    }).addCase(signOutAsync.pending, (state) => {
      state.isLoading = true
    }).addCase(signOutAsync.fulfilled, (state) => {
      state.isLoading = false
      state.isAuthenticated = false
      state.user = initialState.user
    }).addCase(signOutAsync.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.error = { code: action.error.code, message: action.error.message }
    }).addCase(authStateChangeAsync.pending, (state) => {
      state.isLoading = true
    }).addCase(authStateChangeAsync.fulfilled, (state, action) => {
      state.isLoading = false
      state.isAuthenticated = true
      state.user = action.payload?.user
    }).addCase(authStateChangeAsync.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.error = { code: action.error.code, message: action.error.message }
    }).addCase(remoteConfigAsync.pending, (state) => {
      state.isLoading = true
    }).addCase(remoteConfigAsync.fulfilled, (state) => {
      state.isLoading = false
      state.isError = false
      state.error = undefined
    }).addCase(remoteConfigAsync.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.error = { code: action.error.code, message: action.error.message }
    })
  }
})

export const { authStateChange } = authSlice.actions
export const selectAuthUser = (state: RootState) => state.auth.user
export const selectIsAuth = (state: RootState) => state.auth.isAuthenticated
export const selectIsAppInitializing = (state: RootState) => state.auth.isInitializing
export const selectIsAuthLoading = (state: RootState) => state.auth.isLoading
export const selectLoadingBackdrop = (state: RootState) => state.auth.isLoading || state.auth.isInitializing
export default authSlice.reducer


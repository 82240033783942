import React from 'react'
import AppPage from '../../components/AppPage'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectIsAuth, signInAsync } from '../../features/auth/authSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import googleIcon from '../../assets/icons8-google.svg'
import Navigate2 from '../../components/Navigate2'
export type ProtectedRedirectState = {
  from: Location
}

const Login = () => {
  const isAuth = useAppSelector(selectIsAuth)
  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const redirectTo = (location && location.state) ? (location.state as ProtectedRedirectState).from.pathname : ''

  return (
    isAuth === true ? <Navigate2 to={redirectTo && redirectTo.length > 0 ? redirectTo : '/about'} /> : <AppPage sx={{ justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', minHeight: '100%' }}>
        <Button sx={{ marginBottom: 5, width: '300px', alignSelf: 'center' }}
          startIcon={<img src={googleIcon}></img>}
          size='large' variant='outlined' onClick={() => { dispatch(signInAsync({ redirectTo })) }}>
          Google
        </Button>
        <Button onClick={() => navigate(-1)} size="small" sx={{ marginBottom: 5, width: '300px', alignSelf: 'center' }}>Back</Button>
      </Box>
    </AppPage >
  )
}

export default Login
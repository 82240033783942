import React from 'react'
import AppPage from '../../components/AppPage'
import {
  Typography, Grid, Button, Box, CardContent, CardMedia, Card, CardHeader, Link,
} from '@mui/material'
import hosting from '../../assets/Hosting.png'
import distribution from '../../assets/Distribution.png'
import mobile from '../../assets/Mobile.png'
import ecomm from '../../assets/Automation.png'
import women from '../../assets/Women.png'
import cloud from '../../assets/Cloud.png'
import solutions from '../../assets/Solutions.png'
import performance from '../../assets/Performance.png'
import grow from '../../assets/Grow.png'

const serviceCards = [
  { img: distribution, title: 'Website Development', desc: 'Designing and building professional websites tailored to clients\' needs, including e- commerce sites, corporate websites, and personal blogs.' },
  { img: mobile, title: 'Mobile App Development', desc: 'Creating custom mobile applications for iOS, Android, or cross-platform, catering to various industries and functionalities.' },
  { img: hosting, title: 'UI/UX Design', desc: 'Designing intuitive and visually appealing user interfaces and experiences for websites, mobile apps, and software products.' },
  { img: women, title: 'Software Development', desc: 'Developing custom software solutions for businesses, including enterprise resource planning (ERP) systems, customer relationship management (CRM) software, and workflow automation tools.' },
  { img: ecomm, title: 'E-commerce Solutions', desc: 'Building online stores and integrating e-commerce platforms with payment gateways, inventory management systems, and other essential features.' },
  { img: solutions, title: 'IT Consulting', desc: 'Providing strategic IT consulting services to help businesses align their technology investments with their goals and optimize their IT infrastructure and operation.' },
  { img: cloud, title: 'Cloud Computing Services', desc: 'Offering cloud-based solutions, including cloud infrastructure setup, migration, and management, as well as Software as a Service (SaaS) and Platform as a Service (PaaS) offerings.' },
  { img: performance, title: 'IT Support Services', desc: 'Providing ongoing technical support, maintenance, and managed IT services to ensure the smooth operation and security of clients\' technology infrastructure.' },
  { img: grow, title: 'Microservice', desc: 'Specialize in decomposing monoliths into microservices, transforming bulky and rigid apps into smaller, nimble components to enhance flexibility, scalability and agility.' },
]

const About = () => {
  return (<AppPage>
    <Card sx={{ maxWidth: { xs: 350, sm: 500, md: 756 }, margin: 'auto', mb: 5, boxShadow: 15 }}>
      <CardMedia
        component="img"
        src={hosting}
        alt="A Random picture found with the keyword Tokyo"
      />
      <CardHeader title="" subheader="Realm of innovation knows no confines" />
      <CardContent>
        <Typography paragraph sx={{ textAlign: 'justify' }}>
          At Rathnas, we transcend the label of mere tech enthusiasts. We are visionaries, innovators, and solution architects committed to pushing the boundaries within the expansive digital landscape. Our focus lies in crafting exceptional website and mobile app solutions, driven by a team of skilled and impassioned individuals.
        </Typography>
      </CardContent>
    </Card>

    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Typography variant="h3" gutterBottom>
          Who We Are
        </Typography>
        <Typography paragraph sx={{ textAlign: 'justify' }}>
          We are a group of architects, designers, and developers who take on
          challenges and embrace creativity. Our diverse backgrounds and
          expertise mingle and form a powerhouse of talent, ready to tackle
          any project with precision and ingenuity.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h3" gutterBottom>
          What We Do
        </Typography>
        <Typography paragraph sx={{ textAlign: 'justify' }}>
          From crafting sleek and user-friendly websites to developing
          cutting-edge mobile applications, we love to bring your digital
          visions to life. Whether you&apos;re a small startup or a multinational
          corporation, we tailor our services to suit your unique needs and
          objectives.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
        }}
      >
      </Grid>
    </Grid>

    <Typography variant="h3" gutterBottom>
      Our services
    </Typography>

    <Grid container spacing={10} sx={{ justifyContent: 'center' }}>
      {(serviceCards).map((card, index) => <Grid item key={index}>
        <Card sx={{ maxWidth: 350, boxShadow: 15 }}>
          <CardMedia sx={{
            filter: 'grayscale(50%)',
            transition: 'filter 1s ease, transform 0.3s ease',
            '&:hover': { filter: 'none', transform: 'scale(1.05)' }
          }} component="img" image={card.img} alt={card.title} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {card.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {card.desc}
            </Typography>
          </CardContent>
        </Card>
      </Grid>)}
    </Grid>
    <Box style={{ marginTop: '2rem' }}>
      <Typography variant="h3" gutterBottom>
        Let&apos;s Connect
      </Typography>
      <Typography paragraph>
        Ready to embark on your digital journey with us? Get in touch with us
        today to discuss your project ideas, and let&apos;s create something
        extraordinary together!
      </Typography>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', mb: 2 }}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          href="mailto:info@rathnas.com"
          sx={{ textAlign: 'center' }}>
          Contact Us
        </Button>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Typography variant="body2" gutterBottom>
          info@rathnas.com |  <Link href="https://linkedin.com/company/rathnas" target="_blank" rel="noreferrer">LinkedIn</Link>
        </Typography>
      </Box>
    </Box>
  </AppPage>)
}

export default About
import React from 'react'
import AppPage from '../../components/AppPage'
import { Box, Button, Typography } from '@mui/material'

export interface AppInfoProp {
  mode: 'bar' | 'page'
}

const AppInfo = (props: AppInfoProp) => {
  return (props.mode === 'page' ? <AppPage>
    <Typography variant="body1">{process.env.REACT_APP_GH_BUILD_DATE}</Typography>
    <Typography variant="caption">{process.env.REACT_APP_GH_BUILD_VERSION}</Typography>
    <Typography variant="caption">
      {process.env.REACT_APP_GH_BUILD_RUN_ID}.{process.env.REACT_APP_GH_BUILD_RUN_NUMBER}.{process.env.REACT_APP_GH_BUILD_RUN_ATTEMPT}
    </Typography>
    <Button variant="text" color="secondary" href={process.env.REACT_APP_GH_BUILD_COMMIT_URL}>
      {process.env.REACT_APP_GH_BUILD_COMMIT_USER}
    </Button>
  </AppPage> : props.mode === 'bar' ? <Box sx={{ position: 'absolute', bottom: 10, right: 10 }}>
    <Typography variant="caption">{process.env.REACT_APP_GH_BUILD_VERSION?.substring(0, 7)}</Typography>
  </Box> : <></>)
}

export default AppInfo
import React, { useEffect } from 'react'
import Container from '@mui/material/Container'
import { Box } from '@mui/material'
import ResponsiveAppBar from './components/ResponsiveAppBar'
import { useAppDispatch } from './app/hooks'
import { authInitAsync, authStateChangeAsync, remoteConfigAsync } from './features/auth/authSlice'
import { onAuthStateChanged } from 'firebase/auth'
import { Route, Routes, useLocation } from 'react-router-dom'
import Home from './pages/common/home'
import Login from './pages/common/login'
import Pnf from './pages/common/pnf'
import ProtectedRoute from './components/ProtectedRoute'
import Profile from './pages/common/profile'
import ResponsiveFooter from './components/ResponsiveFooter'
import AppInfo from './pages/common/appinfo'
import About from './pages/common/about'
import Navigate2 from './components/Navigate2'
import LoadingBackdrop from './components/LoadingBackdrop'
import { fireauth } from './app/fire'
import { setSubtitle, setTitle } from './features/app/appSlice'

let debounceTimer: NodeJS.Timeout

const titles = (pathname: string) => {
  if (pathname.startsWith('/profile')) {
    return { title: 'Profile', subtitle: '' }
  } else if (pathname.startsWith('/app')) {
    return { title: 'App Info', subtitle: '' }
  } else if (pathname.startsWith('/about')) {
    return { title: 'About us', subtitle: '' }
  } else if (pathname.startsWith('/contact')) {
    return { title: 'Contact', subtitle: '' }
  } else if (pathname.startsWith('/login')) {
    return { title: 'Login', subtitle: '' }
  } else if (pathname.startsWith('/home')) {
    return { title: 'Rathnas.us', subtitle: '' }
  } else {
    return { title: 'Oops! Page Not Found', subtitle: '' }
  }
}

function App() {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const authStateChangeAsyncDebounced = () => { dispatch(authStateChangeAsync()) }
  useEffect(() => {
    onAuthStateChanged(fireauth, (usr) => {
      console.log(`onAuthStateChanged: ${usr?.email}`)
      if (usr) {
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(authStateChangeAsyncDebounced, 500)
      } else {
        clearTimeout(debounceTimer)
      }
    })
  }, [dispatch])

  useEffect(() => {
    dispatch(authInitAsync())
    dispatch(remoteConfigAsync())
  }, [dispatch])

  useEffect(() => {
    dispatch(setTitle(titles(location.pathname).title))
    dispatch(setSubtitle(titles(location.pathname).subtitle))
  }, [location.pathname, dispatch])

  return (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <ResponsiveAppBar />
    <Container maxWidth="xl" sx={{ minHeight: 'calc(100vh - 130px)' }}>
      <Routes>
        {/* <Route path="/" element={<Navigate to="/home" />} /> */}
        <Route path="/" element={<Navigate2 to="/about" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/app" element={<AppInfo mode="page" />} />
        <Route path="/about" element={<About />} />
        <Route element={<ProtectedRoute redirectTo="/login" />}> {/* https://stackoverflow.com/a/69592617 */}
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route path="*" element={<Pnf />} />
      </Routes>
      <LoadingBackdrop />
    </Container>
    <ResponsiveFooter />
    <AppInfo mode="bar" />
  </Box>
  )
}

export default App
